<template>
    
    <page page="algemene-voorwaarden" theme="light" type="page">

        <div class="container">
            <div class="row section">
                <div class="col-md-12">
                    <h3>Algemene voorwaarden van Bed &amp; Breakfast De Oude Stoffeerderij.</h3>
                    <p>
                        Wij heten u van harte welkom bij Bed &amp; Breakfast De Oude Stoffeerderij in Dussen en verzoeken u zich op de hoogte te stellen van deze algemene voorwaarden.
                        <br/>
                        Met het aangaan van een gemaakte reservering worden deze algemene voorwaarden van kracht.
                    </p>
                    <p>
                        <strong>Hoofdgast.</strong>
                        <br/>
                        De persoon die bij B&amp;B De Oude Stoffeerderij heeft gereserveerd middels een contactformulier, e-mail of via telefonisch contact.
                        <br/>
                        De hoofdgast dient 18 jaar of ouder te zijn en is verantwoordelijk voor zijn of haar mede gasten en dient een vaste woon - of verblijfplaats te hebben.
                    </p>
                    <p>
                        <strong>Beheerder.</strong>
                        <br/>
                        De persoon die als eigenaar van B&amp;B De Oude Stoffeerderij of namens de eigenaar van B&amp;B De Oude Stoffeerderij beheert.
                    </p>
                    <p>
                        <strong>Derden.</strong>
                        <br/>
                        Iedere andere rechtspersoon die geen gast, beheerder of eigenaar is.
                    </p>
                    <p>
                        <strong>Contact &amp; Reserveringen.</strong>
                        <br/>
                        Via onze website: www.deoudestoffeerderij.nl&nbsp;of door een e-mail te sturen naar: info@deoudestoffeerderij.nl&nbsp;of door te bellen met telefoonnummer: 06-22388927.
                    </p>
                    <p>
                        <strong>Annulering.</strong>
                        <br/>
                        Het herroepen of ontbinden van de definitieve reservering binnen de daarvoor geldende termijn.
                    </p>
                    <p>
                        <strong>Verblijf.</strong>
                        <br/>
                        De gehele periode gelegen tussen inchecken en uitchecken.
                    </p>
                    <p>
                        <strong>Contactgegevens.</strong>
                        <br/>
                        Bed &amp; Breakfast De Oude Stoffeerderij Evert en Ria Pruissen Dorpsstraat 85 4921 AB Dussen Telefoonnummer: 06-22388927 Email: info@deoudestoffeerij.nl Website: www.deoudestoffeerderij.nl
                    </p>
                    <p>
                        <u><strong>1.Bed &amp; Breakfast</strong></u>
                         <br/>
                        <strong>
                            1.
                        </strong>
                        De website www.deoudestoffeerderij.nl is met de grootste zorg samengesteld, maar er kunnen verschillen zijn tussen de op de website gepresenteerde tekst en/of foto&rsquo;s en de actuele situatie bij Bed &amp; Breakfast De Oude Stoffeerderij ten tijde van het bezoek door onze gasten.
                        <br/>
                        Er kunnen door de gasten geen rechten worden ontleend aan dergelijke verschillen.
                        <br/>
                        <br/>
                        <strong>
                            1.2
                        </strong>
                        B&amp;B De Oude Stoffeerderij is geschikt voor vier personen.
                        <br/>
                        Een extra opklapbed is eventueel bij te boeken tot maximaal 5 personen evenals een babybed bijgeboekt kan worden.
                    </p>
                    <p>
                        <strong>
                            1.3
                        </strong>
                        Op de dag van aankomst kunt u inchecken vanaf 15.00 uur tot 18.00 uur.
                        <br/>
                        Afwijkende tijden zijn alleen mogelijk in overleg.
                        <br/>
                        Uitchecken op de dag van vertrek v&oacute;&oacute;r 11.00 uur, tenzij anders is overeengekomen.
                        <br/>
                        <br/>
                        <strong>
                            1.4
                        </strong>
                        Het ontbijt wordt geserveerd tussen 08.00 uur en 10.00 uur in de B&amp;B De Oude Stoffeerderij. Afwijkende tijden zijn alleen mogelijk in overleg.
                        <br/>
                        Als u speciale wensen heeft voor het ontbijt, zoals&nbsp;glutenvrij, zoutarm, vegetarisch enz. wilt u dit dan aangeven als u de reservering bij ons maakt. Hiervoor vragen wij wel een extra toeslag per persoon, afhankelijk van uw wensen.
                        <br/>
                        Het is niet mogelijk om dit alsnog achteraf bij te reserveren.
                        <br/>
                        <br/>
                        <strong>
                            1.5
                        </strong>
                        B&amp;B De Oude Stoffeerderij beschikt over een eigen badkamer met douche, vaste wastafel met warm &amp; koud stromend water, toilet en is&nbsp;inclusief gebruik van bed -en badlinnen.
                    </p>
                    <p>
                        <strong>
                            1.6
                        </strong>
                        De auto kan worden geparkeerd op het eigen terrein en op de dijk, maar parkeren geschiedt geheel op eigen risico.
                        <br/>
                        <br/>
                        <strong>
                            1.7
                        </strong>
                        De priv&eacute;ruimtes mogen niet betreden worden door gasten.
                        <br/>
                        De achtergelegen wei is van een hobby-boer en mag niet betreden worden. De pad voor de B&amp;B dient altijd vrij te blijven, omdat de hobby-boer recht van overpad heeft.
                    </p>
                    <p>
                        <strong>
                            1.8
                        </strong>
                        Tussen 22.00 uur en 8.00 uur wordt de avond -en nachtrust gerespecteerd voor de omliggende woningen.
                    </p>
                    <p>
                        <strong>
                            1.9
                        </strong>
                        In B&amp;B De Oude Stoffeerderij zijn maximaal 2 huisdieren toegestaan. In de binnentuin mogen de huisdieren los lopen, maar op het erf moeten de huisdieren aan de lijn. Wij verzorgen hondenpoepzakjes, die op het erf en op de dijk gebruikt moeten worden. In B&amp;B De Oude Stoffeerderij zijn een hondenkussen, drink -en voerbakken aanwezig.
                    </p>
                    <p>
                        <strong>
                            1.10
                        </strong>
                        In de B&amp;B De Oude Stoffeerderij is het niet toegestaan om te gourmetten en/of fonduen ivm stankoverlast voor nieuwe gasten. In de binnentuin mogen de gasten barbecue&euml;n, gourmetten en fonduen.
                    </p>
                    <p>
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                2. Prijzen
                            </strong>
                        </u>
                    </p>
                    <p>
                        <strong>
                            2.1
                        </strong>
                        De prijzen van de kamers zijn beschreven op onze website: www.deoudestoffeerderij.nl
                        <br/>
                        Prijzen zijn inclusief uitgebreid ontbijt, gebruik van bed -en badlinnen, water en elektriciteit, toeristenbelasting, maximaal 2 huisdieren en de eindschoonmaak.
                        <br/>
                        <br/>
                        <strong>
                            2.2
                        </strong>
                        &nbsp;De prijzen zijn exclusief kosten van een annulering- en/of reisverzekering en/of andere kosten. U dient zelf zorg te dragen voor een annulerings- en/of reisverzekering.
                        <br/>
                        <br/>
                        <strong>
                            2.3
                        </strong>
                        Prijzen van Bed &amp; Breakfast De Oude Stoffeerderij zijn steeds vrijblijvend en geschieden onder voorbehoud van tussentijdse wijzigingen.
                        <br/>
                        <br/>
                        <strong>
                            2.4
                        </strong>
                        Alle vermeldingen op onze websites worden geacht te goeder trouw te zijn verstrekt en geschieden steeds onder voorbehoud van tussentijdse aanpassingen.
                        <br/>
                        <br/>
                        <strong>
                            2.5
                        </strong>
                        Bed &amp; Breakfast De Oude Stoffeerderij&nbsp;is niet aan kennelijke fouten op haar websites gebonden.
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                3. Reservering en betaling
                            </strong>
                        </u>
                    </p>
                    <p>
                        <strong>
                            3.1
                        </strong>
                        Reserveren geschiedt per mail www.deoudestoffeerderij of via telefoonnummer 06-22388927.
                        <br/>
                        <br/>
                        <strong>
                            3.2
                        </strong>
                        Na een gemaakte reserveringsaanvraag ontvangt u van ons zo spoedig mogelijk bericht met daarin de betreffende gegevens en de vermelding en of de B&amp;B nog beschikbaar is. U dient deze gegevens te controleren en te bevestigen binnen 48 uur door een e-mail terug te sturen naar info@deoudestoffeerderij.nl of op andere wijze schriftelijk te bevestigen.
                        <br/>
                        Door de reserveringsaanvraag te bevestigen wordt de boeking pas definitief.
                        <br/>
                        Mochten wij na 48 uur geen bevestiging van u terug hebben ontvangen, dan wordt de reserveringsaanvraag alsnog per direct geannuleerd. Dit om teleurstelling voor onze andere gasten te voorkomen.
                        <br/>
                        <br/>
                        <strong>
                            3.3
                        </strong>
                        &nbsp;Minimaal zeven dagen na de bevestiging van de reservering&nbsp;dient u de aanbetaling , zijnde vijftig procent (50%) van het totaalbedrag, over te maken naar bankrekeningnummer: NL 21 KNAB 0257 9785 93 t.n.v. Meubelstoffeerderij Ria Pruissen.
                        <br/>
                        Het resterende openstaande bedrag kunt u bij aankomst contant betalen of vooraf door overboeking naar bovenstaand rekeningnummer.
                        <br/>
                        Bij reserveringen op korte termijn dient het bedrag bij aankomst contant betaald te worden of vooraf door overboeking naar bovenstaand bankrekeningnummer.
                        <br/>
                        Mocht er onverhoopt na de afgesproken termijn niets zijn ontvangen, dan komt de gemaakte reservering per direct te vervallen.
                        <br/>
                        U ontvangt hierover van B&amp;B De Oude Stoffeerderij een bevestiging van annulering.
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                4. Annuleringsvoorwaarden
                            </strong>
                        </u>
                    </p>
                    <p>
                        &nbsp;
                        <strong>
                            4.1
                        </strong>
                        Wanneer een definitieve reservering voor B&amp;B De Oude Stoffeerderij is gemaakt en bevestigd, geldt voor annulering van die reservering het navolgende:
                        <br/>
                        -&nbsp;Bij annulering tot 4 weken voor de aankomstdatum krijgt u de hele aanbetaling teruggestort minus &euro; 15,= administratiekosten
                        <br/>
                        - Bij annulering van 4 weken tot 7 dagen voor de aankomstdatum bedragen de&nbsp;annuleringskosten 50%.
                        <br/>
                        -&nbsp;Bij annulering binnen 7 dagen of minder voor de aankomstdatum bedragen de&nbsp;annuleringskosten 100 %.
                        <br/>
                        <br/>
                        Om eventueel in aanmerking te komen voor teruggave van het betaalde bedrag dient u de annulering zo spoedig mogelijk per e-mail of telefonisch aan ons door te geven.
                        <br/>
                        Na overleg zal het afgesproken bedrag zo spoedig mogelijk aan u worden overgemaakt, doch uiterlijk binnen 21 dagen.
                        <br/>
                        Bij voortijdig vertrek vindt geen restitutie plaats. Als u niet komt opdagen (No Show) zijn de annuleringskosten 100 %
                    </p>
                    <p>
                        <strong>
                            4.3
                        </strong>
                        Bed &amp; Breakfast De Oude Stoffeerderij kan de reserveringsovereenkomst slechts opzeggen in de volgende gevallen: in geval van overmacht, natuurramp, buitengewone weersomstandigheden, brand, overlijden van de eigenaren of familie, ziekenhuisopname of onaangekondigde verkoop.
                        <br/>
                        In &eacute;&eacute;n van deze gevallen zal de eigenaar de gast zo spoedig mogelijk een alternatief aanbod doen toekomen.
                        <br/>
                        Is dit niet mogelijk of accepteert de gast het aangeboden alternatief niet, dan zal terugstorting van het volledige bedrag plaatsvinden.
                        <br/>
                        Enige andere schade ten gevolge van de opzegging zal niet door de eigenaar worden vergoed.
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                5. Klachten
                            </strong>
                        </u>
                        <br/>
                        <br/>
                        <strong>
                            5.1
                        </strong>
                        Wij proberen uw verblijf zo aangenaam mogelijk te laten verlopen.
                        <br/>
                        Ondanks onze goede zorgen, is het toch mogelijk dat u een klacht of opmerking heeft.
                        <br/>
                        Om die naar behoren te kunnen oplossen vragen wij u om dit zo spoedig mogelijk aan ons kenbaar te maken ter plekke, via mail of telefonisch contact.
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                6. Aansprakelijkheid
                            </strong>
                        </u>
                        <br/>
                        <br/>
                        <strong>
                            6.1
                        </strong>
                        Bed &amp; Breakfast De Oude Stoffeerderij&nbsp;kan niet aansprakelijk worden gehouden voor door de gast of derden ten gevolge van het verblijf in de B&amp;B geleden schade, de gast vrijwaart Bed &amp; Breakfast De Oude Stoffeerderij tegen aanspraken dienaangaande.
                        <br/>
                        Bed and Breakfast De Oude Stoffeerderij is niet aansprakelijk voor storingen in en om de accommodatie zoals storingen en uitval van stroom, Wifi , watervoorzieningen en technische installaties als ook niet of ontijdig aangekondigde bouw en/of wegwerkzaamheden in de nabijheid van Bed &amp; Breakfast De Oude Stoffeerderij.
                        <br/>
                        <br/>
                        <strong>
                            6.2
                        </strong>
                        Door Bed &amp; Breakfast De Oude Stoffeerderij wordt geen aansprakelijkheid aanvaardt voor verlies, diefstal, schade of ongevallen, veroorzaakt aan personen, huisdieren en/of goederen tijdens of ten gevolge van het verblijf in de B&amp;B De Oude Stoffeerderij.
                        <br/>
                        <br/>
                        <strong>
                            6.3
                        </strong>
                        De hoofdgast genoemd in de reserveringsbevestiging is verantwoordelijk voor een ordelijke gang van zaken in en om de B&amp;B De Oude Stoffeerderij. Er dient zorgvuldig met de spullen te worden omgegaan in de B&amp;B en de binnenplaats. Huisdieren mogen geen gebruik maken van de bank en/of de bedden.
                        <br/>
                        De B&amp;B en de binnenplaats dienen netjes en fatsoenlijk te worden achtergelaten.
                        <br/>
                        Breuk of beschadiging dient dan ook door de gast direct aan de eigenaar te worden gemeld.
                        <br/>
                        Eventuele reparatie- en/of vervangingskosten dienen door de gast onmiddellijk aan de eigenaar op diens eerste verzoek te worden vergoed.
                        <br/>
                        Mocht achteraf na het uitchecken toch blijken dat er spullen kapot zijn achtergelaten en dit is niet aan de eigenaar gemeld, dan volgt alsnog de rekening voor de gemaakte reparatie- en/of vervangingskosten.
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </p>
                    <p>
                        <u>
                            <strong>
                                7. Veiligheid
                            </strong>
                        </u>
                    </p>
                    <p>
                        <strong>
                            7.1
                        </strong>
                        In verband met uw eigen veiligheid en dat van de Bed and Breakfast is het verboden om binnen in de B&amp;B te roken of open vuur te gebruiken.
                        <br/>
                        Dit is alleen toegestaan in de binnentuin. Er bevinden zich in de B&amp;B een rookmelder en een CO2 melder.
                        <br/>
                        Het misbruik van brand/blusmiddelen dan wel het onklaar maken van de rook -en CO2 melder is niet toegestaan.
                    </p>
                    <p>
                        <strong>
                            7.2
                        </strong>
                        Er is in de B&amp;B een EHBO koffertje aanwezig.
                        <br/>
                        <br/>
                        <strong>
                            7.3
                        </strong>
                        Andere voorzieningen op het erf dienen met zorg en overeenkomstig de bestemming te worden gebruikt door de gasten.
                        <br/>
                        De veiligheid van kinderen en huisdieren dienen door de gasten of begeleiders gewaarborgd te zijn.
                        <br/>
                        Denk hierbij aan de trap, kachel, huisdieren, sloten, auto&rsquo;s, trekkers, open verbinding naar de dijk, de sloot enz.
                        <br/>
                        <br/>
                        <strong>
                            7.4
                        </strong>
                        Het is niet toegestaan de dieren op het erf zonder overleg te voeren, inclusief onze eigen hond Noa.
                        <br/>
                        <br/>
                        <u>
                            <strong>
                                8. Slotbepaling
                            </strong>
                        </u>
                        <br/>
                        <br/>
                        <strong>
                            8.1
                        </strong>
                        Voor zover in regels van internationaal privaatrecht niet anders bepaald, is op de onderhavige voorwaarden uitsluitend Nederlands recht van toepassing. Niet Nederlandstalige gasten worden geacht akkoord te zijn met de algemene voorwaarden.
                        <br/>
                        <br/>
                        <strong>
                            8.2
                        </strong>
                        Alle geschillen voortvloeiende uit de gebruikersovereenkomst of deze voorwaarden zullen in eerste aanleg worden beslecht door de bevoegde rechter in Nederland, voor zover de regels van internationaal privaatrecht niet anders bepalen.
                        <br/>
                        <br/>
                        <strong>
                            8.3
                        </strong>
                        Geen van de partijen kan haar rechten en verplichtingen aan derden overdragen voor zover in de onderhavige voorwaarden niets anders is bepaald.
                        <br/>
                        <br/>
                        <strong>
                            8.4
                        </strong>
                        Indien en voor zover enige bepaling in de gebruikersovereenkomst en de onderhavige voorwaarden nietig mocht blijken, blijven de overige voorwaarden in stand en wordt het nietige artikel aldus geacht te zijn geconverteerd dat het in overeenstemming met de kennelijke bedoelingen van partijen wordt gebracht.
                        <br/>
                        <br/>
                        Mochten er verder nog vragen of opmerkingen zijn dan horen wij dat graag!
                        <br/>
                        <br/>
                        Vriendelijke groeten,
                        <br/>
                        <br/>
                        Evert en Ria Pruissen
                    </p>
                </div>
            </div>
        </div>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
export default {
    metaInfo: {
        title: 'Algemene voorwaarden',
        meta: [
            { vmid: 'description', name: 'description', content: 'Algemene voorwaarden van Bed &amp; Breakfast De Oude Stoffeerderij.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/algemene-voorwaarden'}
        ]
    },
    components: {
        Page
    }
}
</script>
